
import Currency from '@/modules/common/components/currency.vue';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import type Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import Intraday from '@/modules/common/components/intraday.vue';
import UserService, { UserServiceS } from '../../../../user/user.service';
import BaseCard from './base-card.vue';

@Component({
    filters: { PercentFilter, PriceFilter },
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        Currency,
        RatesDayScanBtn,
        BaseCard,
        Intraday,
    },
})

export default class Available extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    cardColorClass() {
        const color = this.ratesService.getCardAssessment(this.day);

        switch (color) {
            case ASSESSMENTS_TYPES.GOOD:
                return 'green';
            case ASSESSMENTS_TYPES.NORMAL:
                return 'yellow';
            case ASSESSMENTS_TYPES.BAD:
                return 'red';
            default:
                return 'gray';
        }
    }

    get isIntraday() {
        if (this.ratesService.isCheapestChannel) return false;

        return this.ratesService.isIntraday(this.day);
    }

    get isIntradaySpecial() {
        const room = this.ratesService.getRoom(this.day, this.hotelId);

        return !!room?.intradayBySpecialDate;
    }

    get competitionPercent() {
        return this.ratesService.getCompetitionPercent(this.day);
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get price() {
        return this.ratesService.getPrice(this.day, this.hotelId);
    }

    get losRestriction() {
        if (!this.userService.currentHotelId) {
            return false;
        }

        const losRestriction = this.ratesCommonService
            .getHotelLosRestriction(this.day, this.userService.currentHotelId, this.ratesService.data);

        if (!losRestriction) {
            return false;
        }

        return losRestriction;
    }
}
