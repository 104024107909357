
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import ScanBtn from '@/modules/common/components/ui-kit/scan-btn.vue';

import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesService, { RatesServiceS } from '../rates.service';

import SCAN_STATUS from '../constants/scan-status.constant';
import RatesScanTooltip from './rates-scans-tooltip.vue';

@Component({
    components: {
        ScanBtn,
        CustomTooltip,
        RatesScanTooltip,
    },
})
export default class RatesScan extends Vue {
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;

    tooltipPos: HTMLDivElement | null = null;
    estimatedRatioTimeout: NodeJS.Timer | null = null;
    estimatedRatio: number = 0;

    beforeDestroy() {
        if (this.estimatedRatioTimeout) {
            clearInterval(this.estimatedRatioTimeout);
        }
    }

    get isScanning() {
        return this.ratesService.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        if (this.ratesService.isLoading) {
            return true;
        }

        const { scanLimitation } = this.ratesCommonService;

        const haveNoScans = scanLimitation && scanLimitation.remaining === 0;
        return !this.ratesService.isScanAvailable() || haveNoScans;
    }

    get lastScanDate() {
        return this.ratesService.finishScanDate;
    }

    get title() {
        return this.disabled && this.documentFiltersService.isPreviousMonth ? 'Not allowed to scan previous months' : '';
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        this.$gtag.event('on-demand', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        await this.ratesService.triggerScan();
    }

    get progress() {
        const updateInterval = 5000;
        const { ratio, estimation, startTime } = this.ratesService.scan.values;

        if (this.estimatedRatioTimeout) {
            clearTimeout(this.estimatedRatioTimeout);
        }

        const updateEstimatedRatio = () => {
            if (startTime === null) {
                this.estimatedRatio = 0;
                return;
            }
            this.estimatedRatio = ((Date.now() - new Date(startTime).getTime()) / 1000) / estimation;
        };

        updateEstimatedRatio();
        this.estimatedRatioTimeout = setTimeout(updateEstimatedRatio, updateInterval);

        return {
            percentDone: ratio * 50 + Math.min(this.estimatedRatio, 1) * 50,
            secondsLeft: estimation,
        };
    }
}
